import React from "react";
import { useHistory } from "react-router";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import CardPage from "../../../layout/CardPage";
import { BuscaAsistenciaID } from "./BuscaAsistenciaID";
import { BuscarOrdenPagoID } from "./BuscarOrdenPagoID";
import { ModalSolicitaLiberar } from "./_ModalSolicitaLiberar";

export const Busca = () => {
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  return (
    <CardPage>
      <div style={{ width: 300, marginTop: 10, marginBottom: 50 }}>
        <BotonSubmit
          onClick={() => history.push("/ordenesPago/crear")}
          tituloBoton="Cerar Orden Nueva"
        />
      </div>
      <BuscarOrdenPagoID />
      <BuscaAsistenciaID />
      <div style={{marginTop: 30}}>
        Para poder generar OP de asistencias, estas deben ser menor a 4 meses,
        de otro modo deberá solicitar su liberación.
      </div>
      <div style={{width: 300}}>
        <BotonSubmit
          onClick={() => setOpen(true)}
          tituloBoton="Solictar Liberar Asistencias"
        />
      </div>
      <ModalSolicitaLiberar open={open} setOpen={setOpen} />
      <div style={{ height: 200 }}></div>
    </CardPage>
  );
};
