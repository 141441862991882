import React from "react";
import { File } from "./_File";
import { Respuesta } from "./Respuesta";
import { Button } from "antd";
import { useProps } from "./useProps";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const FormaFilesComplemento = () => {
  const {ordenPagoID} = useParams();
  const { body, propsXml, propsPdf, disabled, submit } =
    useProps(ordenPagoID);

  return (
    <div>
      <File
        props={propsXml}
        titulo="Cargar Complemento XML"
        file={body.complementoXml}
        fileErr={body.complementoXmlErr}
      />
      <File
        props={propsPdf}
        titulo="Cargar Complemento PDF"
        file={body.complementoPdf}
        fileErr={body.complementoPdfErr}
      />

      <Button
        loading={body.loading}
        style={{ margin: "auto" }}
        onClick={submit}
        disabled={disabled}
        type="primary"
      >
        Enviar Archivos{" "}
      </Button>

      <Respuesta body={body} />
    </div>
  );
};
