import { Modal } from "antd";
import { FormaFilesComplemento } from "./formaFilesComplemento/FormaFilesComplemento";
import { TituloModal } from "../../../../../../components/tituloModal/TituloModal";

export const CargaComplementoPagoModal = ({ showModal, setShowModal }) => {
  //////////////
  return (
    <Modal
      title={<TituloModal titulo="Cargar Complemento de Pago" />}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={[]}
    >
      <FormaFilesComplemento />
    </Modal>
  );
};
