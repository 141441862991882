import pjson from "../../package.json";

export const ver = pjson.version;
export const nameProject = pjson.name;
export const { ambiente } = window["runConfig"];
export const { URL_BASE } = window["runConfig"];


export const URL_BASE_REPORTES = `${URL_BASE}/api/reportes`;
export const URL_BASE_REPORTES2 = `${URL_BASE}/api/reportes2`;

export const URL_BASE_ALLOWEDBROWSER = `${URL_BASE}/api/AllowedBrowser`;

export const URL_BASE_ASISTENCIAS = `${URL_BASE}/api/asistencias`;
export const URL_BASE_ASIGNACION = `${URL_BASE}/api/asignacion`;

export const URL_BASE_CALIDAD = `${URL_BASE}/api/calidad`;

export const URL_BASE_VER = `${URL_BASE}/api/Ver`;
export const URL_BASE_XLS = `${URL_BASE}/api/xls`;
export const URL_BASE_PROVEEDORES_XLS = `${URL_BASE}/api/XlsProveedores`;

export const URL_BASE_ORDENESPAGO = `${URL_BASE}/api/OrdenPago`;
export const URL_BASE_ORDENESPAGO_BUSCAR = `${URL_BASE}/api/OrdenPagoBuscar`;

export const URL_UPLOAD = `${URL_BASE}/api/upload`;
export const URL_LOGIN = `${URL_BASE}/api/auth/login`;

export const URL_GENERICOS = `${URL_BASE}/api/genericos`;

export const URL_BASE_CLIENTES = `${URL_BASE}/api/clientes`;
export const URL_POST_CAMPANIA = `${URL_BASE}/api/clientes/postCampania`;
export const URL_UPDATE_CAMPANIA = `${URL_BASE}/api/clientes/updateCampania`;

export const URL_POST_CAMPANIA_SERVICIOS = `${URL_BASE}/api/clientes/postCampaniaServicios`;
export const URL_UPLOAD_FILE = `${URL_BASE}/api/clientes/PostDocumento`;

//campaniaID/tipoAsistenciaID
export const URL_GET_CAMPANIA_SERVICIOS = `${URL_BASE}/api/clientes/GetCampaniaServicios`;
export const URL_GET_DOCUMENTOS = `${URL_BASE}/api/clientes/GetDocumentos`;

export const URL_DOWNLOAD_DOCUMENTO = `${URL_BASE}/api/clientes/GetDocumento`;
export const URL_DELETE_DOCUMENTO = `${URL_BASE}/api/clientes/DeleDocumento`;
export const URL_POST_SCRIPT = `${URL_BASE}/api/clientes/PostScript`;

export const URL_BASE_PROVEEDORES = `${URL_BASE}/api/proveedores`;
export const URL_BASE_TIPOSASISTENCIA = `${URL_BASE}/api/tiposAsistencia`;

//
export const URL_BASE_LISTAS = `${URL_BASE}/api/listas`;
export const URL_BASE_USUARIOS = `${URL_BASE}/api/auth`;
export const URL_BASE_EDITAR_ASISTENCIA = `${URL_BASE}/api/EditarAsistencia`;
export const URL_BASE_CAMBIO_COSTO = `${URL_BASE}/api/CambioCosto`;

export const URL_SELFSERVICE_SERVICIOS = `${URL_BASE}/api/SelfServiceAdminServicios`;

