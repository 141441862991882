import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import BotonDivL from "../../../components/Boton/BotonDivL";
//
import {
  startGetAsistencia,
  startGetServicioAsistencia,
  startUpdateDireccion,
} from "../../../context/actions/asistenciasActions";
import CardPage from "../../../layout/CardPage";
import { Forma } from "./Forma";
import { Mapa } from "./mapa/Mapa";

export const EditarAsistencia = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tipo, setTipo] = useState("");
  const { asistenciaID } = useParams();
  const { latDestino, lngDestino, direccionOrigen, direccionDestino } =
    useSelector((state) => state.asistenciasReducer.asistencia);
  useEffect(() => {
    dispatch(startGetAsistencia(asistenciaID));
    dispatch(startGetServicioAsistencia(asistenciaID));
  }, [dispatch, asistenciaID]);

  const handleChange = (data) => {
    const body = { ...data, tipo };
    dispatch(startUpdateDireccion(body));
  };

  const getLat = (lat) => {
    if (!lat || isNaN(lat)) return 19.393015;
    return Number(lat);
  };

  const getLng = (lng) => {
    if (!lng || isNaN(lng)) return -99.173026;
    return Number(lng);
  };

  return (
    <>
      <CardPage
        titulo={`Asistencia: ${asistenciaID}`}
        accionBoton={() => history.push("/asistencias")}
        tituloBoton="Regresar"
      >
        <Forma />
      </CardPage>

      <CardPage titulo={`Cambiar ${tipo && tipo}`}>
        <Row>
          <Col>
            <BotonDivL
              tituloBoton="Mapa Origen"
              onClick={() => setTipo("Origen")}
            />
          </Col>
          <Col>
            <BotonDivL
              tituloBoton="Mapa Destino"
              onClick={() => setTipo("Destino")}
            />
          </Col>
        </Row>
        {tipo === "Origen" && (
          <Mapa
            onChange={handleChange}
            lat={getLat(latDestino)}
            lng={getLng(lngDestino)}
            direccionFull={direccionOrigen}
          />
        )}
        {tipo === "Destino" && (
          <Mapa
            onChange={handleChange}
            lat={getLat(latDestino)}
            lng={getLng(lngDestino)}
            direccionFull={direccionDestino}
          />
        )}
      </CardPage>
    </>
  );
};
