import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startSolicitarCambioCosto } from "../../../../context/actions/asistenciasActions";

export const useForm = () => {
  const dispatch = useDispatch();
  const [body, setBody] = useState({});
  const [open, setOpen] = useState(false);
  const { asistenciaID } = useParams();

  const submit = () => {


    const final = { ...body, asistenciaID };
    dispatch(startSolicitarCambioCosto(final));
    setBody({});
    setOpen(false);

  };

  const disabled = () => {
    if(!body.motivo)
      return true;

    if(body.costoNuevo === undefined || body.costoNuevo === null)
      return true;

    if(body.costoNuevo < 0)
      return true;



  };

  return { body, setBody, open, setOpen, submit, disabled };
};
