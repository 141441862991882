import { Col, Row } from "antd";
import React from "react";

export default function LabelValor2Link({
  label,
  valor,
  linkLabel,
  onClick,
  linkLabel2,
  onClick2,
}) {
  return (
    <>
      <div className="label">{label}</div>
      <div className="valor">
        <Row gutter={10}>
          <Col span={15}>{valor}</Col>
          <Col span={4} onClick={onClick} className="linkOP">
            {linkLabel}
          </Col>
          <Col span={4} onClick={onClick2} className="linkOP">
            {linkLabel2}
          </Col>
        </Row>
      </div>
    </>
  );
}
