import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import {
  startGetComplementoDePagoFile,
} from "../../../../../../context/actions/ordenesPagoActions";
import { CargaComplementoPagoModal } from "./CargaComplementoPagoModal";
import LabelValor2Link from "../../../../../../components/label/LabelValor2Link";

export const CargaComplementoPago = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const {
    estatusOrdenPagoID,
    fechaCargaComplementoPago,
    usuarioCargaComplementoPago,
    ordenPagoID,
  } = useSelector((state) => state.ordenesPagoReducer.ordenPago);

  const getFile = (tipo) =>
    dispatch(startGetComplementoDePagoFile({ordenPagoID, tipo})); // tipo; xml o pdf

  ////////////////////
  if (estatusOrdenPagoID < 6) return <div></div>;
  if (estatusOrdenPagoID === 6)
    return (
      <>
        <BotonSubmit
          style={{ marginTop: 18 }}
          tituloBoton="Cargar Complemento de Pago"
          onClick={() => setShowModal(true)}
        />
        <CargaComplementoPagoModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </>
    );

  return (
    <LabelValor2Link
      label="Complemento de pago"
      valor={`${fechaCargaComplementoPago} - ${usuarioCargaComplementoPago}`}
      linkLabel="xml"
      onClick={() => getFile("xml")}
      linkLabel2="pdf"
      onClick2={() => getFile("pdf")}
    />
  );
};
