import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  startChangeCosto,
  startPuedeCambiarCosto,
} from "../../../../context/actions/asistenciasActions";
import { correosCabiarCosto } from "../../../../constants/cons";

export const useForm = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();

  const { costo } = useSelector((state) => state.asistenciasReducer.asistencia);
  const email = useSelector((state) => state.userReducer.user.email);
  const { puedeCambiarCosto } = useSelector(
    (state) => state.asistenciasReducer
  );

  const [openModal, setOpenModal] = useState(false);
  const [costoNuevo, setCostoNuevo] = useState(costo);
  const costoMayorRol = useSelector(
    (state) => state.userReducer.user.Backoffice_CostoMayor
  );

  useEffect(() => {
    dispatch(startPuedeCambiarCosto(asistenciaID));
  }, [dispatch, asistenciaID]);

  /////
  const handleOk = () => {
    const esMenor = costoNuevo < costo;

    const body = {
      asistenciaID,
      costo: Number(costoNuevo),
    };

    if (esMenor) {
      if (costoNuevo && costoNuevo >= 0) dispatch(startChangeCosto(body));
    } else {
      if (
        costoNuevo &&
        costoNuevo >= 0 &&
        (correosCabiarCosto.includes(email) || costoMayorRol)
      )
        dispatch(startChangeCosto(body));
    }
    setOpenModal(false);
  };

  return {
    costoNuevo,
    setCostoNuevo,
    openModal,
    setOpenModal,
    handleOk,
    puedeCambiarCosto,
  };
};
