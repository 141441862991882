import { EditOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Select, Tooltip } from "antd";

import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import BotonSubmit from "../../../components/Boton/BotonSubmit";
import LabelText from "../../../components/input/LabelText";
import LabelSelect from "../../../components/select/LabelSelect";
import { cuentaEspecialGNPOpciones } from "../../../constants/cons";

import {
  setAsistenciaDato,
  startEditarDatosGenerales,
} from "../../../context/actions/asistenciasActions";
import { CambioServicio } from "./cambioServicio/CambioServicio";
import { QuitaProveedor } from "./quitaProveedor/QuitaProveedor";
import { ControlesCambios } from "./controlesCambio/ControlesCambios";
import { SolicitarCambioCosto } from "./solicitarCambioCosto/SolicitarCambioCosto";

const { Option } = Select;

///////////
export const Forma = () => {
  const dispatch = useDispatch();
  const { tipoAsistencia, servicio } = useSelector(
    (state) => state.asistenciasReducer.servicioAsistencia
  );

  const usuario = useSelector((state) => state.userReducer.user);

  const {
    asistenciaID,
    asegurado,
    nombreLlamada,
    apellidoPaternoLlamada,
    apellidoMaternoLlamada,
    tipoPersona,
    campo1,
    campo2,
    campo3,
    campo4,
    campo5,
    campo6,
    campo7,
    campo8,
    campo9,
    campo10,
    campo11,
    campo12,
    costo,
    costoCliente,
    costoAutorizado,
    quienAutoriza,
    localCarretero,
    proveedorSucursal,
    estatus,
    estatusFinal,
    cuentaEspecialGNP,
  } = useSelector((state) => state.asistenciasReducer.asistencia);
  ////

  if (!asegurado) return <>cargando</>;

  const change = ({ target }) => {
    const dato = { [target.name]: target.value };
    dispatch(setAsistenciaDato(dato));
  };

  const handleCuentaEspecial = (val) => {
    const dato = { cuentaEspecialGNP: val };
    dispatch(setAsistenciaDato(dato));
  };

  const handleSubmit = () => {
    dispatch(startEditarDatosGenerales());
  };
  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3}>
          <LabelText disabled label="AsistenciaID" value={asistenciaID} />
        </Col>
        <Col span={3}>
          <LabelText disabled label="Validador1" value={asegurado.validador1} />
        </Col>
        <Col span={4}>
          <LabelText disabled label="Estatus" value={estatus.nombre} />
        </Col>
        <Col span={3}>
          <LabelText disabled label="Conclusión" value={estatusFinal.nombre} />
        </Col>
        <Col span={5}>
          <Col>
            <LabelText
              disabled
              label="Servicio"
              value={`${tipoAsistencia} / ${servicio}`}
            />
          </Col>
        </Col>

        <Col span={6}>
          {proveedorSucursal && (
            <LabelText
              disabled
              label="Proveedor"
              value={`${proveedorSucursal?.proveedor?.nombreComercial} / ${proveedorSucursal?.nombre}`}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col>
          <LabelText
            label="Nombre"
            name="nombreLlamada"
            onChange={change}
            value={nombreLlamada}
          />
        </Col>
        <Col>
          <LabelText
            label="Apellido Paterno"
            name="apellidoPaternoLlamada"
            onChange={change}
            value={apellidoPaternoLlamada}
          />
        </Col>
        <Col>
          <LabelText
            label="Apellido Materno"
            name="apellidoMaternoLlamada"
            onChange={change}
            value={apellidoMaternoLlamada}
          />
        </Col>
        <Col>
          <LabelText
            label="Tipo de persona"
            value={tipoPersona}
            name="tipoPersona"
            onChange={change}
          />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col>
          <LabelText
            label="Campo 1"
            value={campo1}
            name="campo1"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 2"
            value={campo2}
            name="campo2"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 3"
            value={campo3}
            name="campo3"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 4"
            value={campo4}
            name="campo4"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 5"
            value={campo5}
            name="campo5"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 6"
            value={campo6}
            name="campo6"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 7"
            value={campo7}
            name="campo7"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 8"
            value={campo8}
            name="campo8"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 9"
            value={campo9}
            name="campo9"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 10"
            value={campo10}
            name="campo10"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 11"
            value={campo11}
            name="campo11"
            onChange={change}
          />
        </Col>
        <Col>
          <LabelText
            label="Campo 12"
            value={campo12}
            name="campo12"
            onChange={change}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={4}>
          <div className="labelImput">Local / carretero</div>

          <Select
            defaultValue={localCarretero}
            onChange={(val) =>
              dispatch(setAsistenciaDato({ localCarretero: val }))
            }
            style={{ width: "100%" }}
          >
            <Option value="local">local</Option>
            <Option value="carretero">carretero</Option>
          </Select>
        </Col>
        <Col span={4}>
          <LabelText
            disabled={true}
            label="Costo"
            value={costo}
            name="costo"
            onChange={change}
          />
        </Col>
        <Col span={4}>
          <LabelText
            label="Costo cliente"
            value={costoCliente}
            name="costoCliente"
            onChange={change}
          />
        </Col>
        <Col span={4}>
          <LabelText
            label="Costo autorizado"
            value={costoAutorizado}
            name="costoAutorizado"
            onChange={change}
          />
        </Col>
        <Col span={4}>
          <LabelText
            label="Quién autoriza"
            value={quienAutoriza}
            name="quienAutoriza"
            onChange={change}
          />
        </Col>
        <Col span={4}>
          <LabelSelect
            label="Cuenta especial GNP"
            name="cuentaEspecialGNP"
            value={cuentaEspecialGNP}
            onChange={handleCuentaEspecial}
            opciones={cuentaEspecialGNPOpciones}
          />
        </Col>
      </Row>
      <Col span={6}>
        <BotonSubmit onClick={handleSubmit} tituloBoton="Actualizar Datos" />
      </Col>

      <Divider />

      <SolicitarCambioCosto />
      <ControlesCambios />
    </>
  );
};
