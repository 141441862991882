import { Button, Modal } from "antd";
import React from "react";
import { TituloModal } from "../../../components/tituloModal/TituloModal";
import LabelTextarea from "../../../components/input/LabelTextarea";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { startSolicitarLiberacionDeAsistenciaParaOP } from "../../../context/actions/opServiciosActions";
import { useDispatch } from "react-redux";

export const ModalSolicitaLiberar = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [body, setBody] = React.useState({});

  const handlechange = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });

  const submit = () => {
    dispatch(startSolicitarLiberacionDeAsistenciaParaOP(body))
    setOpen(false);
    setBody({})
  };

  const disabled = !body.motivo || !body.folios;

  return (
    <Modal
      title={
        <TituloModal titulo="Solicitar Liberar Asistencias para Crear OP" />
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button danger size="small" key="back" onClick={() => setOpen(false)}>
          Cerrar
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 20 }}>
        <LabelTextarea
          name="motivo"
          onChange={handlechange}
          value={body.motivo}
          label="Motivo de la solicitud"
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <LabelTextarea
          name="folios"
          onChange={handlechange}
          value={body.folios}
          label="Folios de Asistencia"
        />
        <span style={{ color: "#999" }}>
          sin espacios y separados por coma, ejemplo: AGUI_0001,AGUI_0002
        </span>
      </div>
      <div style={{ marginBottom: 20 }}>
        <BotonSubmit disabled={disabled} onClick={submit} tituloBoton="Solictar" />
      </div>
    </Modal>
  );
};
