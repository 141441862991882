import React from "react";
import { useDispatch } from "react-redux";
import { postFile } from "../../../../../../../utils/fetch";
import { getFileExt } from "../../../../../../../utils/getFileExt";
import { URL_BASE_ORDENESPAGO } from "../../../../../../../constants/url";
import { startGetOrdenPago } from "../../../../../../../context/actions/ordenesPagoActions";
import { notification } from "../../../../../../../context/actions/notificationActions";
import { composeSuccessNotification } from "../../../../../../../utils/composeNotification";

//
export const useProps = (ordenPagoID) => {
  const dispatch = useDispatch();
  const [body, setBody] = React.useState({});

  const propsXml = {
    beforeUpload: (file) => {
      if (getFileExt(file.name).toLowerCase() !== "xml") {
        setBody({
          ...body,
          complementoXml: null,
          complementoXmlErr: `El archivo ${file.name} no es XML`,
        });
        return false;
      } else {
        setBody({ ...body, complementoXml: file, complementoXmlErr: null });
      }
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const propsPdf = {
    beforeUpload: (file) => {
      if (getFileExt(file.name).toLowerCase() !== "pdf") {
        setBody({
          ...body,
          complementoPdf: null,
          complementoPdfErr: `El archivo ${file.name} no es PDF`,
        });
        return false;
      } else if (body.verificacion?.name === file.name) {
        setBody({
          ...body,
          complementoPdf: null,
          complementoPdfErr: `El archivo ${file.name} no puede ser igual al de verificación`,
        });
        return false;
      } else {
        setBody({ ...body, complementoPdf: file, complementoPdfErr: null });
      }
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const disabled = !body.complementoXml || !body.complementoPdf;
  const submit = async () => {
    setBody({ ...body, respuestaOk: null, respuestaErr: null, loading: true });
    const formData = new FormData();
    formData.append("ComplementoPagoPDF", body.complementoPdf);
    formData.append("ComplementoPagoXml", body.complementoXml);
    formData.append("OrdenPagoID", ordenPagoID);

    const resp = await postFile(
      `${URL_BASE_ORDENESPAGO}/CargaComplementoPago`,
      formData
    );

    if (resp.code === 0) {
      setBody({ respuestaOk: resp.data, loading: false });
      dispatch(startGetOrdenPago(ordenPagoID));
      dispatch(notification(composeSuccessNotification(resp.data)));
    } else {
      setBody({ ...body, respuestaErr: resp.error, loading: false });
    }
  };

  return { body, propsXml, propsPdf, disabled, submit };
};
