import { Button, Input, Modal } from "antd";
import React from "react";
import BotonDivL from "../../../../components/Boton/BotonDivL";
import { TituloModal } from "../../../../components/tituloModal/TituloModal";
import { useForm } from "./useForm";

export const CambioCosto = () => {
  const {
    costoNuevo,
    setCostoNuevo,
    openModal,
    setOpenModal,
    handleOk,
    puedeCambiarCosto,
  } = useForm();

  //
  return (
    <>
      <BotonDivL
        tituloBoton="Cambiar Costo"
        onClick={() => setOpenModal(true)}
      />
      <Modal
        title={<TituloModal titulo="Cambiar Costo" />}
        open={openModal}
        footer={[]}
        onCancel={() => setOpenModal(false)}
      >
        {puedeCambiarCosto !== true ? (
          <div style={{ textAlign: "center", padding: 30, fontSize: 20 }}>
            Para poder cambiar el costo
            <br /> Se debe hacer la solicitud del cambio y motivo
          </div>
        ) : (
          <>
            <div style={{marginBottom: 10}}>
              <Input
                value={costoNuevo}
                onChange={({ target }) => setCostoNuevo(target.value)}
              />
            </div>
            <div style={{marginBottom: 30}}>
              <Button type="primary" onClick={handleOk}>
                Actualizar el Costo
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
