import React from "react";
import { dateFromString } from "../../../../utils/dateFromString";
import { Col, Row } from "antd";
import { CambioServicio } from "../cambioServicio/CambioServicio";
import { QuitaProveedor } from "../quitaProveedor/QuitaProveedor";
import { useSelector } from "react-redux";
import { CambioConclusion } from "../cambioConclusion/CambioConclusion";
import { CambioCosto } from "../cambioCosto/CambioCosto";
import { CambioProveedor } from "../cambioProveedor/CambioProveedor";

export const ControlesCambios = () => {
  const { fechaCargaFactura, ordenPagoID } = useSelector(
    (state) => state.asistenciasReducer.asistencia
  );
  return (
    <>
      {fechaCargaFactura ? (
        <div>
          La asistencia ya tiene factura cargada el{" "}
          {dateFromString(fechaCargaFactura).soloFecha} en la OP: {ordenPagoID}{" "}
          no se puede hacer cambio
        </div>
      ) : (
        <Row gutter={10}>
          <Col span={4}>
            <CambioServicio />
          </Col>
          <Col span={4}>
            <CambioCosto />
          </Col>
          <Col span={4}>
            <CambioConclusion />
          </Col>
          <Col span={4}>
            <CambioProveedor />
          </Col>
          <Col span={4}>
            <QuitaProveedor />
          </Col>
        </Row>
      )}
    </>
  );
};
