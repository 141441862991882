export const AccesoBackOfficeOptions = [
  "Asistencias",
  "Clientes",
  "Proveedores",
  "TiposAsistencia",
  "Pagos",
  "Usuarios",
  "CambioEspecial",
  "Calidad",
  "AltaProveedores",
  "DescargarProveedores",
  "AdminLibrary", // Permite subir archivos a la librería en cabina
  "CostoMayor", // Permite cambiar el costo de una asistencia a uno menor // Para el costo menor todos pueden actualmente
  "CambioProveedor",
  "Reportes",
];

export const ACCION_EXITOSA = "Accion Exitosa";
export const EERROR_ENVIO = "Error de Envío";

export const cuentaEspecialGNPOpciones = [
  { texto: "DACERO", valor: "DACERO" },
  { texto: "DANONE", valor: "DANONE" },
  { texto: "EL ORO", valor: "EL ORO" },
  { texto: "PFS", valor: "PFS" },
  { texto: "SHCP", valor: "SHCP" },
  { texto: "RENT A MATIC ITZA", valor: "RENT A MATIC ITZA" },
  { texto: "TEQUILA SAUZA", valor: "TEQUILA SAUZA" },
  { texto: "AUTOFLETES CHIHUAHUA", valor: "AUTOFLETES CHIHUAHUA" },
  { texto: "ELEMENT", valor: "ELEMENT" },
  { texto: "CONSERVAS LA COSTEÑA", valor: "CONSERVAS LA COSTEÑA" },
  { texto: "ALPURA", valor: "ALPURA" },
];

export const tipoCampo = [
  { texto: "texto", valor: "texto" },
  { texto: "lista", valor: "lista" },
];

export const siNo = [
  { texto: "Si", valor: true },
  { texto: "No", valor: false },
];

export const plOptions = [
  { label: "PL 1", value: "geo1" },
  { label: "PL 2", value: "geo2" },
];

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGF0OTAwMCIsImEiOiJjbDI1ajk2cDkwMmNqM2tsZTE2ZWEyZTVrIn0.456pSXyxhu6zvPolqHsdtQ";

export const mesesOptions = [
  { texto: "Ene", valor: 1 },
  { texto: "Feb", valor: 2 },
  { texto: "Mar", valor: 3 },
  { texto: "Abr", valor: 4 },
  { texto: "May", valor: 5 },
  { texto: "Jun", valor: 6 },
  { texto: "Jul", valor: 7 },
  { texto: "Ago", valor: 8 },
  { texto: "Sep", valor: 9 },
  { texto: "Oct", valor: 10 },
  { texto: "Nov", valor: 11 },
  { texto: "Dic", valor: 12 },
];

export const mesesOptions2 = [
  { label: "Ene", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Abr", value: 4 },
  { label: "May", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Ago", value: 8 },
  { label: "Sep", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dic", value: 12 },
];

export const estatusOptions = [
  { texto: "Concluido", valor: 2 },
  { texto: "Otorgado", valor: 3 },
  { texto: "Cancelado con costo", valor: 4 },
  { texto: "Cancelado sin costo", valor: 5 },
];

export const estatusCanceladosOptions = [
  { texto: "Cancelado con costo", valor: 4 },
  { texto: "Cancelado sin costo", valor: 5 },
];

export const cambioConclusion = [
  "ricardo.perez@escotel.com.mx",
  "pat9000@hotmail.com",
  "miguel.morales@escotel.com.mx",
  "jennifer.perez@escotel.com.mx",
];

export const correosReporte = [
  "pat9000@hotmail.com",
  "angelica.herrera@escotel.com.mx",
  "fernando.aguirre@escotel.com.mx",
  "j.moragrega@escotel.com.mx",
];

export const emailCambiarProveedor = [
  "ricardo.perez@escotel.com.mx",
  "miguel.morales@escotel.com.mx",
  "jennifer.perez@escotel.com.mx",
  "pat9000@hotmail.com",
];
export const correosCabiarCosto = [
  "angel.aguirre@escotel.com.mx",
  "pat9000@hotmail.com",
  "jose.almaguer@escotel.com.mx",
];

export const correosConfirmaFactura = [
  "jacqueline.ochoa@escotel.com.mx",
  "dante.delcastillo@escotel.com.mx",
  "elizabeth.cortes@escotel.com.mx",
  "andrea.jose@escotel.com.mx",
  "pat9000@hotmail.com",
  "becario.facturacion@escotel.com.mx",
  "kevin.romero@escotel.com.mx",
  "miguel.cote@escotel.com.mx",
  "monica.padilla@escotel.com.mx"
];

export const correosAutorizaOPPrimerNivel = [
  "pat9000@hotmail.com",
  "angelica.herrera@escotel.com.mx",
  "j.moragrega@escotel.com.mx",
];

export const correosAutorizaOPSegundoNivel = [
  "pat9000@hotmail.com",
  "angelica.herrera@escotel.com.mx",
];

export const opcionesUsuriosUtorizarMasivo = [
  { texto: "Jose Moragrega", valor: "8de97c53-6ab1-4c30-8d0c-b1b657945cd9" },
  { texto: "Angelica Herrera", valor: "d8b506b7-873a-4a79-aa08-9245f773ffbc" },
];

export const correosPuedeAutorizarMasivo = ["pat9000@hotmail.com"];

export const correosReportes = [
  "pat9000@hotmail.com",
  "angelica.herrera@escotel.com.mx",
  "j.moragrega@escotel.com.mx",
  "fernando.aguirre@escotel.com.mx",
  "dante.delcastillo@escotel.com.mx"
];
