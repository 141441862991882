import { Button, Modal } from "antd";
import React from "react";
import { TituloModal } from "../../../../components/tituloModal/TituloModal";
import LabelNumber from "../../../../components/input/LabelNumber";
import LabelTextarea from "../../../../components/input/LabelTextarea";
import { useForm } from "./useForm";

export const SolicitarCambioCosto = () => {
  const { body, setBody, open, setOpen, submit, disabled } = useForm();
  

  ///
  return (
    <div style={{ margin: 20 }}>
      <Button onClick={() => setOpen(true)} type="primary">
        Solicitar Cambio de Costo
      </Button>

      <Modal
        open={open}
        title={<TituloModal titulo="Solicitar Cambio de Costo" />}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        <div style={{ marginBottom: 10 }}>
          <LabelNumber
            onChange={({ target }) =>
              setBody({ ...body, costoNuevo: target.value })
            }
            label="Nuevo Costo"
            value={body.costoNuevo}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <LabelTextarea
            onChange={({ target }) =>
              setBody({ ...body, motivo: target.value })
            }
            label="Motivo (detallado)"
            value={body.motivo}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <Button disabled={disabled()} onClick={submit} type="primary">
            Enviar
          </Button>
        </div>
      </Modal>
    </div>
  );
};
